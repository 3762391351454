import React from "react";
import BigHeadline from "./BigHeadline";
import TwoColumnContent from "./TwoColumnContent";
import CustomSection from "./CustomSection";
import TabbedContent from "./TabbedContent"
import TeamMembers from "./TeamMembers"
import CardCollection from "./CardCollection";
import {ImageGrid, ImageCarousel} from "./ImageCollection";
import HeroCarousel from "./HeroCarousel";
import HeroDefault from "../Hero/HeroDefault";
import HeroImage from "../Hero/HeroImage";
import HeroVideo from "../Hero/HeroVideo";
import MediaSection from "./MediaSection";
import Testimonials from "../Testimonials";
import CareerPostListItem from "./CareerPostListItem";
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {customColor} from "../../lib/helpers";
import style from "./Content.module.css";

const Text = ({ children }) =>
    children.join('').length ? <div className="container__paragraph uk-container uk-container-xsmall uk-margin-small">
    <p>{children}</p></div> : ""
const Bold = ({ children }) => <span className="uk-text-bold">{children}</span>;
const Italic = ({ children }) => <span><em>{children}</em></span>;
const Underline = ({ children }) => <span style={{textDecoration: "underline"}}>{children}</span>;
const List = ({ children, ...props }) => {
    const listItems = props.node.content.map(c => c.content.map(c => c.content[0].value));
    return(
        <ul className="uk-list uk-list-bullet">
            {listItems.map((item, i) =>
                <li key={`item=${i}`}>{item}</li>
            )}
        </ul>
    )
}
const BlockQuote = ({ children, ...props }) => {
    return (
        <blockquote className="uk-text-muted">
            {props.node.content.map(c => c.content[0].value)}
        </blockquote>
    )
}
const Hyperlink = ({node, children}) => <a href={node.data.uri}>{children}</a>;
const Hrule = ({node, children}) => <hr className="uk-hr uk-width-3-5 uk-margin-large uk-margin-auto-left uk-margin-auto-right" />;

export const layoutOptions = {

    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
        [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
        [MARKS.CODE]: text => <span dangerouslySetInnerHTML={{__html: text}} />
    },
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const { title, description, file } = node.data.target.fields;
            const mimeType = file.contentType;
            const mimeGroup = mimeType.split('/')[0];
            switch (mimeGroup) {
                case 'image':
                    return <div className="uk-container uk-container-xsmall"><img
                            title={ title ? title : null}
                            alt={description ?  description : null}
                            src={file.url}
                            /></div>
                case 'video':
                    return <video controls="controls">
                                <source src="media/shuttle.mp4" type="video/mp4" />
                                Your browser does not support the HTML5 Video element.
                            </video>
                default:
                    return <span style={{backgroundColor: 'red', color: 'white'}}> {mimeType} embedded asset</span>
            }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
            const fields = node.data.target.fields;
            switch (node.data.target.sys.contentType.sys.id) {
                case "heroCarousel":
                    return <HeroCarousel
                                props={node}
                                collectionName={fields.collectionName}
                                heroCtaModules={fields.heroCtaModules}
                                slug={fields.slug}
                                />
                case "heroCta":
                    switch(fields.templateLayout) {
                        case "Hero Image":
                            return <HeroImage data={fields} />
                        case "Hero Video":
                            return <HeroVideo data={fields} />
                        default:
                            return <HeroDefault data={fields} />
                    }
                case "bigHeadline":
                    return <BigHeadline
                                copy={fields.copy}
                                textAlign={fields.textAlign}
                                displayWidth={fields.displayWidth}
                                sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                slug={fields.slug} />
                case "sectionItem":
                    return <TwoColumnContent
                                title={fields.title}
                                copy={fields.copy}
                                image={fields.image}
                                textAlign={fields.textAlign ? fields.textAlign : null}
                                slug={fields.slug}
                                />
                case "customSection":
                    return <CustomSection
                                title={fields.title}
                                copy={fields.copy}
                                image={fields.image}
                                sectionColor={fields.sectionColor}
                                textAlign={fields.textAlign ? fields.textAlign : null}
                                slug={fields.slug}
                                />
                case "cardCollection":
                    return <CardCollection
                                headline={fields.headline ? fields.headline : null}
                                subHeadline={fields.subHeadline ? fields.subHeadline : null}
                                cards={fields.cards}
                                displayFormat={fields.displayFormat}
                                displayWidth={fields.displayWidth}
                                sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                slug={fields.slug}
                                />
                case "tabbedContent":
                    return <TabbedContent
                                collectionName={fields.collectionName}
                                contentModules={fields.contentModules}
                                displayWidth={fields.displayWidth}
                                sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                slug={fields.slug}
                                headline={fields.headline ? fields.headline : null}
                                />
                case "team":
                    return <TeamMembers
                                collectionName={fields.collectionName}
                                teamMembers={fields.teamMembers}
                                slug={fields.slug}

                                />
                case "careerPost":
                    return <CareerPostListItem
                                title={fields.title}
                                jobSummary={fields.jobSummary}
                                publishDate={fields.publishDate}
                                location={fields.location}
                                slug={fields.slug} />
                case "imageCollection":
                    let backgroundColor = customColor(fields.sectionColor)
                    backgroundColor = backgroundColor !== "None" ? backgroundColor : null
                    let images = [];
                    fields.images.map(a => {
                        return images.push({
                            url: a.fields.file.url,
                            alt: a.fields.title
                        })
                    });
                    return (
                        <section
                            id={fields.collectionIdentity}
                            className={`uk-section uk-section-large ${backgroundColor ? backgroundColor : ""}`}>
                            <div className="uk-padding-small">
                                {fields.displayFormat ==='carousel' ?
                                    <ImageCarousel
                                        slug={fields.collectionIdentity}
                                        headline={fields.headline ? fields.headline : null}
                                        subHeadline={fields.subHeadline ? fields.subHeadline : null}
                                        displayWidth={fields.displayWidth}
                                        sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                        images={images}
                                        />
                                    :
                                    <ImageGrid
                                        slug={fields.collectionIdentity}
                                        headline={fields.headline ? fields.headline : null}
                                        subHeadline={fields.subHeadline ? fields.subHeadline : null}
                                        displayWidth={fields.displayWidth}
                                        sectionColor={fields.sectionColor ? fields.sectionColor : null}
                                        images={images}
                                        />
                                }
                            </div>
                        </section>
                    )
                case "messageCarousel":
                    let messages = [];
                    const items = fields.entries.map((a, i) => a.fields).map((a, i) => {
                        return messages.push({
                            quote: {quote: Object.values(a.quote).toString()},
                            role: Object.values(a.role).toString(),
                            source: Object.values(a.source).toString(),
                        })
                    })
                    return <Testimonials data={messages} items={items} />
                case "mediaSection":
                    return <MediaSection
                            description={fields.description}
                            mediaPath={fields.mediaPath}
                            templateLayout={fields.templateLayout}
                            sectionColor={fields.sectionColor ? fields.sectionColor : null}
                            />
                default:
                    return <div></div>
            }
        },
        [BLOCKS.PARAGRAPH]: (node, children) => <Text node={node}>{children}</Text>,
        [BLOCKS.UL_LIST]: (node, children) => <div className="uk-container uk-container-xsmall"><List node={node}>{children}</List></div>,
        [BLOCKS.QUOTE]: (node, children) => <div className="uk-container uk-container-xsmall"><BlockQuote node={node}>{children}</BlockQuote></div>,
        [BLOCKS.HEADING_2]: (node, children) => <div className="uk-container uk-container-xsmall uk-margin-top"><h2>{children}</h2></div>,
        [BLOCKS.HEADING_3]: (node, children) => <div className="uk-container uk-container-xsmall uk-margin-top"><h3>{children}</h3></div>,
        [BLOCKS.HEADING_4]: (node, children) => <div className="uk-container uk-container-xsmall uk-margin-top"><h4>{children}</h4></div>,
        [BLOCKS.HEADING_5]: (node, children) => <div className="uk-container uk-container-xsmall uk-margin-top"><h5>{children}</h5></div>,
        [BLOCKS.HEADING_6]: (node, children) => <div className="uk-container uk-container-xsmall uk-margin-top"><h6>{children}</h6></div>,
        [BLOCKS.HR]: (node, children) => <Hrule node={node}>{children}</Hrule>,
        [INLINES.HYPERLINK]: (node, children) => <Hyperlink node={node}>{children}</Hyperlink>,
    }
}
