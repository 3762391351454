import React from "react";
import style from "./Content.module.css";

const TeamMembers = ({collectionName, teamMembers, slug, locale}) => {

    return (
        <>
        <section className="uk-section uk-section-large team-members-section">
            <div className="">
                <div className="uk-container">
                    {collectionName.length ?
                        <div className="uk-text-center uk-margin-large-bottom">
                            <h2 className="uk-margin-remove-vertical">{collectionName}</h2>
                        </div>
                        :
                        null
                    }
                    <div className="uk-margin-auto-left uk-margin-auto-right"
                        data-uk-scrollspy={`cls: uk-animation-fade; target: .profile; delay: ${20};`}>
                        <div className={`uk-grid uk-grid-collapse uk-child-width-1-3@m`}
                            data-uk-grid>
                            {teamMembers.map((item, i) =>
                                <div
                                    key={item.fields.slug}
                                    style={{
                                        backgroundImage: `url(${item.fields.profilePhoto.fields.file.url}`
                                    }}
                                    className={`${style.teamMember} profile uk-inline uk-position-relative`}
                                    data-uk-img>
                                    <div style={{cursor: "zoom-in"}} className="uk-overlay-branded uk-position-cover" data-uk-toggle={`target: #profile-${i}`} />
                                    <div className="uk-width-1-1 uk-overlay uk-position-bottom-left uk-light">
                                        <h3 style={{cursor: "zoom-in"}} className="uk-width-1-1 uk-margin-remove-vertical">
                                            <span data-uk-toggle={`target: #profile-${i}`}>{item.fields.name}</span>
                                            <a href={item.fields.linkedIn}
                                                className="uk-float-right uk-icon-button uk-position-z-index"
                                                target="_blank"
                                                rel="noreferrer"
                                                data-uk-icon="icon: linkedin" />
                                        </h3>
                                        <p style={{cursor: "zoom-in"}} className="uk-margin-remove-vertical" data-uk-toggle={`target: #profile-${i}`}>{item.fields.role}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {teamMembers.map((item, i) =>
            <div key={`profile-${i}`} id={`profile-${i}`} className={`uk-modal-full`} data-uk-modal>
                <div className="uk-modal-dialog">
                    <button className="uk-modal-close-full uk-close-large uk-background-muted uk-margin-remove uk-padding-small uk-position-top-right" type="button" data-uk-close></button>
                    <div className="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle" data-uk-grid>
                        <div className="uk-background-cover"
                            style={{
                                backgroundImage: `url(${item.fields.profilePhoto.fields.file.url}`
                            }}
                            data-uk-height-viewport />
                        <div className="uk-padding-large">
                            <h2 className="uk-margin-remove-vertical">{item.fields.name}</h2>
                            <p className="uk-text-lead uk-margin-remove-vertical">{item.fields.role}</p>
                            <p className="uk-margin">{item.fields.bio}</p>
                            <div className="uk-text-center uk-margin-large">
                                <button
                                    type="button"
                                    className="uk-modal-close uk-button uk-button-text uk-text-bto-blue uk-hidden@s">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}
export default TeamMembers
