import React from "react";
import * as Markdown from 'react-markdown';
import style from "./Hero.module.css";

const HeroVideo = ({data, locale}) => {

    const {
        headline,
        subHeadline,
        videoPath,
        sectionId
    } = data
    const leader = subHeadline ? subHeadline : ""

    return (
        <div id={sectionId} className={`${style.topWrap} ${style.overlayWrap} uk-position-relative uk-light uk-background-secondary`}>
            <div className={`${style.topWrapHeight} uk-position-relative uk-cover-container uk-light uk-flex uk-flex-middle`}>
                <div className={`${style.topContainer} uk-container uk-container-small uk-flex-auto uk-position-relative uk-margin-medium-top`} data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
                    <div
                        className={`${style.heroCopy} uk-width-1-2@s`}
                        data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
                        <h1>{headline}</h1>
                        <div className="subtitle-text uk-visible@s">
                            <Markdown
                                source={leader}
                                escapeHtml={false}
                            />
                        </div>
                    </div>
                </div>
                <iframe
                    src={`${videoPath}?autoplay=1&loop=1&background=1&autopause=0`}
                    autoPlay
                    frameBorder="0"
                    title="BetterTradeOff video header"
                    data-uk-cover
                />
            </div>
        </div>
    )
}

export default HeroVideo;
