import React from "react";
import style from './Video.module.css';

export const Video = ({ videoSrcURL }) => {
    const url = `${videoSrcURL}?autoplay=1&loop=1&background=1&autopause=0`;
    return (
        <div className={`${style.responsiveVideo}`}>
            <iframe
                src={url}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                autoPlay
                frameBorder="0"
                title="BetterTradeOff video component"
            />
        </div>
    )
}

export const VideoCover = ({ videoSrcURL, children }) => {
    const url = `${videoSrcURL}?autoplay=1&loop=1&background=1&autopause=0`;
    return (
        <>
            <iframe
                style={{height: "100%", width: "100%"}}
                src={url}
                autoPlay
                frameBorder="0"
                title="BetterTradeOff video component"
                data-uk-parallax="opacity: 1,0.1; easing:0"
                data-uk-cover
            />
            <div>{children}</div>
        </>
    )
}

export const HtmlVideo = ({ videoSrcURL }) => {
    return (
        <video className={`uk-video uk-box-shadow-small`} autoPlay muted loop>
            <source src={videoSrcURL} type="video/mp4" />
        </video>
    )
}

// export const VideoCover = ({ videoSrcURL, children }) => {
//     return (
//         <div className={`uk-cover-container`}>
//             <div className={style.fullscreenVideo}>
//                 <video className="uk-background-secondary" autoPlay muted loop data-uk-cover>
//                     <source src={video} type="video/mp4" />
//                 </video>
//                 <div style={{minHeight: "800px", position: "relative", zIndex: "2"}} className={`uk-container uk-light uk-flex uk-flex-column uk-flex-center`} data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
//                     {children}
//                 </div>
//             </div>
//
//         </div>
//     )
// }
